'use client';

import React from 'react';
import DOMPurify from 'dompurify';
import { useTypedDispatch } from '@src/redux/store/store.types';
import { loadSvgComplete, loadSvgStart } from '@src/redux/actions/loader/loaderActions';
import { InlineSVGPropTypes } from './InlineSVG.types';
const InlineSVG: React.FC<InlineSVGPropTypes> = ({
  style = {},
  src,
  caption,
  className
}) => {
  const dispatch = useTypedDispatch();
  const figureRef = React.useRef<HTMLIFrameElement>(null);
  React.useEffect(() => {
    if (!src) return;
    dispatch(loadSvgStart({
      id: src
    }));
    const urlParts = src.split('/');
    const idAndFilename = urlParts[urlParts.length - 1];
    fetch(src).then(response => response.text())
    // eslint-disable-next-line
    .catch(error => {
      return '';
    }).then(svgString => {
      const sanitizedSVGString = DOMPurify.sanitize(svgString, {
        USE_PROFILES: {
          svg: true
        },
        ADD_TAGS: ['use', 'path'],
        ADD_ATTR: ['xlink:href', 'transform']
      });
      const modifiedSVGString = sanitizedSVGString.replace(/pattern(\d)/g, `${idAndFilename}$1`);
      return new DOMParser().parseFromString(modifiedSVGString, 'image/svg+xml');
    }).then(parsedSVG => {
      if (figureRef.current) {
        const existingSvg = figureRef.current.querySelector('svg');
        if (existingSvg) {
          figureRef.current.removeChild(existingSvg);
        }

        // Append parsed SVG
        figureRef.current.appendChild(parsedSVG.documentElement);

        // Append style for SVG text
        const style = document.createElement('style');
        style.textContent = `text { font-family: 'Roboto', sans-serif !important; }`;
        figureRef.current.appendChild(style);

        // Append or move the figcaption after the SVG
        let figCaption = figureRef.current.querySelector('figcaption');
        if (!figCaption && caption) {
          figCaption = document.createElement('figcaption');
          figCaption.textContent = caption;
          figCaption.className = 'bottom-[0] z-[100] mt-[1.6rem] text-center text-[1.4rem] italic leading-[1.8rem] text-body-copy--dark 2xl:mx-[3rem]';
        } else if (figCaption && caption) {
          figCaption.textContent = caption;
        }
        if (figCaption) {
          figureRef.current.appendChild(figCaption);
        }
        dispatch(loadSvgComplete({
          id: src
        }));
      }
    });
  }, [src, dispatch, caption]); // Include caption in the dependency array to handle caption updates

  return <figure ref={figureRef} style={style} className={`relative overflow-hidden [&>svg]:block [&>svg]:h-auto [&>svg]:w-full [&>svg]:max-w-[100%] ${className}`} data-sentry-component="InlineSVG" data-sentry-source-file="InlineSVG.tsx" />;
};
export default InlineSVG;