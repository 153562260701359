import React from 'react';

import { CounterAnimationProps, UseCounterAnimationReturnParams } from './TrustedbyStats.types';

import useGsapLoaded from '@src/hooks/useGsapLoaded';

const formatNumber = (value: number) => {
  const numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
  if (value >= 1e6) {
    const millions = value / 1e6;
    return millions % 1 !== 0 ? `${millions.toFixed(1)} million` : `${numberFormat.format(millions)} million`;
  }
  return numberFormat.format(value);
};

const useCounterAnimation = ({ counters }: CounterAnimationProps): UseCounterAnimationReturnParams => {
  const countersRef = React.useRef<HTMLHeadingElement[]>([]);
  const isGsapLoaded = useGsapLoaded(); // Get the loading status of GSAP

  React.useEffect(() => {
    if (!isGsapLoaded) return undefined; // If GSAP is not loaded, do nothing

    const tl = window.gsap.timeline({ repeat: 0, repeatDelay: 1 });

    countersRef.current.forEach((h2Element, index) => {
      if (!h2Element || !counters[index]) return;

      const { start, end } = counters[index];
      const animationTarget = { value: start };

      tl.to(
        animationTarget,
        {
          duration: 1.5,
          value: end,
          ease: 'none',
          onUpdate: () => {
            if (Math.floor(animationTarget.value) >= 999999) {
              tl.pause(); // Pause the timeline at a specific value
              h2Element.innerHTML = formatNumber(end)
                .split('')
                .map((char) => `<span style="opacity: 0;">${char}</span>`)
                .join('');
              window.gsap.to(h2Element.querySelectorAll('span'), {
                opacity: 1,
                duration: 0.35,
                stagger: 0.1,
                ease: 'power1.inOut',
              });
            } else {
              h2Element.innerText = formatNumber(Math.floor(animationTarget.value));
            }
          },
        },
        index === 0 ? '>0.25' : '<0.25',
      );
    });

    // Ensure the cleanup function returns void
    return () => {
      tl.kill();
    };
  }, [isGsapLoaded, counters]);

  const onHandleAddCounters = (el: HTMLHeadingElement) => countersRef.current.push(el);

  return { onHandleAddCounters }; // Correct the function name if necessary
};

export default useCounterAnimation;
